import React from "react";
import MiniCardAbout from "./MiniCardAbout";
import { Link } from "react-router-dom";
import "../LynxStyle.css";
const Main = () => {
  return (
    <div>
      <img src="SMSGPT.jpg" className="img-fluid rounded mx-auto d-block"></img>
      <div className="container">
        <h3 className="display-3  text-center text-white">
          <strong>CHATGPT powered bot!</strong>
        </h3>

        <div className="card   lynx_orangecards lynx_opacity_text">
          <div className="card-body">
            <h4 className="card-title text-center">
              1) DIAL <a href="tel:*130*686*9667#">*130*686*9667#</a> or{" "}
              <a href="http://doi.mtndep.co.za/service/9667?gv_id=1">
                CLICK HERE
              </a>
              To get started
              <br />
              2) SMS your questions to 33275
            </h4>
          </div>
        </div>

        <br />
        <h4 className="text-center text-white">
          <strong>Answer ALL* your questions!</strong>
        </h4>
        <br />
        <h4 className="display-4  text-center  text-white">Ask me anything</h4>
      </div>
      <br />
      <MiniCardAbout />
      <br />
      <br />
      <p className="text-center text-white">
        Powered by Lynx Lifestyle Club
        <Link
          to={"/terms"}
          className="btn btn-link"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Terms
        </Link>
      </p>
    </div>
  );
};
export default Main;
