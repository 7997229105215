import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from "react";
import Main from "./Components/main";
import Terms from "./Components/terms";

function App() {



  return (
    
<Router>
  
       
  <Routes>
 
    <Route path="*"  element={<Main/>}></Route> 
    <Route path="/terms"  element={<Terms/>}></Route>
   </Routes>
  
  

  
    </Router>
    
  );
}

export default App;
