import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";
const Terms = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getKW = (md) => {
    if (md === null) {
      md = "";
    }
    switch (md) {
      case "05":
        return "keyword|R0.50 per day";
      case "1":
        return "keyword|R1 per day";
      case "15":
        return "keyword|R1.50 per day";
      case "2":
        return "lynx|R2 per day";
      case "25":
        return "airtime|R2.50 per day";
      case "3":
        return "lynxwin|R3 per day";
      case "35":
        return "lynxair|R3.50 per day";
      case "4":
        return "keyword|R4 per day";
      case "45":
        return "keyword|R4.50 per day";
      case "5":
        return "keyword|R5 per day";
      case "55":
        return "keyword|R5.50 per day";
      case "6":
        return "keyword|R6 per day";
      case "65":
        return "keyword|R6.50 per day";
      case "7":
        return "keyword|R7 per day";
      case "75":
        return "keyword|R7.50 per day";
      case "":
        return "winr|R2 per day";
    }
  };
  return (
    <div className="container card card-info Lynx_terms">
      <p>
        Please note that by using this web site, you, the user, agree to observe
        and be legally bound by all Terms and Conditions of Use as set out
        below, including any other policies which may appear elsewhere on this
        web site. You also agree to observe and abide by all applicable laws and
        regulations.
      </p>

      <strong>1. General</strong>

      <p>
        <strong>1.1</strong> The site Is open to permanent residents And
        citizens of South Africa over the age of 18 (eighteen) years,
        (""Participant/s""), except any minor And any employee, director,
        member, partner, agent Or consultant Or any person directly Or
        indirectly who controls Or Is controlled by the Promoter And/Or
        immediate family members Of any employee, director, member, partner,
        agent Or consultant Of Or person indirectly Or directly In control Or
        controlled by the Promoter
      </p>
      <p>
        <strong>1.2</strong> In order to access the site, the handset must be
        subscribed and link must be active.
      </p>
      <p>
        <strong>1.3</strong> By subscribing to this service, the subscriber
        agrees to receive future communications, marketing and/or advertising
        from third parties to the number that is subscribed to the service{" "}
      </p>
      <p>
        <strong>1.4</strong> All existing and new subscribers are automatically
        entered into a competition to win airtime and gain access to Lynx
        LifeStyle{" "}
      </p>
      <p>
        <strong>1.5</strong>Use of this web site is undertaken solely at the
        risk of the user.{" "}
      </p>
      <p>
        <strong>1.6</strong> No person, business or web site may frame this site
        or any of the pages on this site in any way whatsoever.{" "}
      </p>
      <p>
        <strong>1.7</strong> No person, business or web site may use any
        technology to search and gain any information from this site without the
        prior written permission of CellBright. Such permission could be
        obtained from the person referred to in clause 5 hereof.
      </p>
      <p>
        <strong>1.8</strong> All Subscribers automatically join the Lynx
        Lifestyle Club
      </p>
      <p>
        <strong>1.9</strong> An active subscribed number is defined as a number
        that is subscribed to one of Lynx Lifestyles services. The subscription
        MUST be active. The msisdn must have billed within the past 3 days.
      </p>
      <strong>2. Competition</strong>
      <p>
        <strong>2.1</strong> All existing subscribers are automatically entered
        into a competition to win airtime
      </p>
      <p>
        <strong>2.2</strong> Winners will receive an SMS as well as an automated
        SMS from the network
      </p>
      <p>
        <strong>2.3</strong> Prizes are awarded daily and/or monthly
      </p>
      <p>
        <strong>2.4</strong> Prizes are in the form of airtime and are
        non-exchangeable and non-transferable
      </p>
      <strong>3. Subscription</strong>
      <p>
        <strong>3.1</strong> MobifyMe content and access to the AI costs{" "}
        {getKW(searchParams.get("md")).split("|")[1]} including VAT to access
        the site.
      </p>

      <p>
        <strong>3.2</strong> In order to access the site or ask the AI
        questions, the handset must be subscribed and link must be active.
      </p>
      <p>
        <strong>3.3</strong> To subscribe SMS{" "}
        {getKW(searchParams.get("md")).split("|")[0]} to 33270(std sms
        rates/sms)({getKW(searchParams.get("md")).split("|")[1]}
        )(or the subscription charge agreed when subscribing).
      </p>
      <p>
        <strong>3.4</strong> Subscribers that don't complete the subscription
        process or who unsubscribe, may receive the opportunity to resubscribe
        via a ussd link sent once every 30 days for 2 months.
      </p>
      <p>
        <strong>3.5</strong> Each existing and new subscriber is automatically
        entered into a competition to win airtime.
      </p>
      <p>
        <strong>3.6</strong> To unsubscribe SMS Stop{" "}
        {getKW(searchParams.get("md")).split("|")[0]} to 33270(std sms
        rates/sms)
      </p>

      <strong>4. Lynx Lifestyle Club</strong>
      <p>
        <strong>4.1</strong> All subscribers agree to receive SMS marketing
        messages from Lynx Lifestyle Club
      </p>
      <p>
        <strong>4.2</strong> To unsubscribe from receiving Marketing Messages
        from Lynx Lifestyle Club SMS stop to 49993(std sms rates/sms)
      </p>
      <strong>Contact</strong>
      <p>
        <strong></strong> For further details email Info@lynxlife.club
      </p>
      <p>
        <strong></strong> Or call 0109003333 (std rates apply)
      </p>
      <p>
        <strong></strong> 13 Park Crescent, Glenhazel, JHB SA
      </p>
      <strong>5. SMSGPT</strong>
      <p>
        <strong>5.1</strong> All ACTIVE subscribers that SMS 33275 will receive
        an AI response powered by CHAT GPT API
      </p>
      <p>
        <strong>5.2</strong> All OpenAI rules and T&Cs apply
      </p>

      <p>
        <strong>5.3</strong> SMS's are charged at Standard SMS rates
      </p>
      <p>
        <strong>5.4</strong> Customers must be subscribed to SMSGPT or to a Lynx
        Lifestyle service in order to use the SMSGPT shortcode (33275)
      </p>
      <p>
        <strong>5.5</strong> To subscribe (MTN ONLY), either dial
        <a href="tel:*130*686*9667#">*130*686*9667#</a> or{" "}
        <a href="http://doi.mtndep.co.za/service/9667?gv_id=1">CLICK HERE</a>
      </p>

      <p>
        <strong>5.6</strong> Questions are limited to 20 per day per subscribed
        number
      </p>
    </div>
  );
};
export default Terms;
